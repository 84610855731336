html {
    width: 100%;
    height: 100%;
    font-size: 20px;
    overflow: hidden;
}

body {
    --font: 'Anonymous Pro', monospace;
    --bg: white;
    --fg: black;
    --fg-light: #00000080;
    --primary: #0f893e;
    --primary-transparent: #0f893e80;

    &.dark {
        --bg: #111;
        --fg: white;
        --fg-light: #ffffffc0;
        --primary: #1cdb65;
        --primary-transparent: #1cdb6580;
    }

    font-family: var(--font);
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--bg);
    color: var(--fg);
}

#app {
    width: 100%;
    max-width: calc(30ch * 2.5 + 1px);
    margin: auto;
    // background-image: linear-gradient(#ffffff40, #ffffff40 10%, transparent 10%, transparent);
    // background-size: 100% 0.5rem;
}

.Landing {
    .title {
        margin: 0 0 2rem;
        font-weight: normal;
        color: var(--fg-light);
        font-size: 2.5rem;
        height: 3rem;

        strong {
            font-weight: bold;
            color: var(--fg);
        }
    }

    form {
        text-align: right;
        margin-bottom: 3rem;

        input {
            appearance: none;
            border: solid var(--fg);
            box-sizing: border-box;
            border-width: 0 0 2px;
            background-color: transparent;
            color: var(--fg);
            height: 2rem;
            font: 1.5rem var(--font);
            width: 100%;
            margin-bottom: 1rem;
            padding: 0;
            transition: 250ms box-shadow, 250ms border-color;

            &:focus {
                outline: none;
                box-shadow: 0 0.3rem 0.2rem -0.2rem var(--primary-transparent);
                border-color: var(--primary);
            }

            &::placeholder {
                opacity: 1;
                color: var(--fg-light);
            }
        }

        button {
            margin-right: 0.2rem;
        }
    }

    p {
        text-align: center;
        margin: 0;
    }
}

.button {
    appearance: none;
    border: none;
    background-color: transparent;
    height: 1rem;
    font: 1rem var(--font);
    color: var(--fg);
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    line-height: 1rem;
    transition: 250ms background-color, 250ms box-shadow;

    &:focus {
        outline: none;

        &:not(.filled) {
            background-color: var(--primary-transparent);
            box-shadow: 0 0 0 0.2rem var(--primary-transparent);
        }

        &.filled {
            box-shadow: 0 0 0 0.2rem var(--fg), 0 0 0 0.4rem var(--primary);
        }
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &.filled {
        background-color: var(--fg);
        color: var(--bg);
        text-decoration: none;
        padding: 0 0.2rem;
        box-shadow: 0 0 0 0.2rem var(--fg);
    }
}

// .visually-hidden {
//     position: absolute !important;
//     height: 1px;
//     width: 1px;
//     overflow: hidden;
//     clip: rect(1px, 1px, 1px, 1px);
// }

.hidden {
    display: none;
}

pre {
    font-family: var(--font);
}

.Analyze {
    h1 {
        text-indent: -2ch;
        font-size: 1.5rem;
        line-height: 2rem;
        word-break: break-all;
        color: var(--fg-light);

        strong {
            color: var(--fg);
        }
    }
}

.Log {
    list-style-type: none;
    padding: none;

    li {
        display: flex;
        line-height: 1rem;
        margin-bottom: 0.5rem;

        .icon {
            margin-right: 1ch;
            height: 1rem;
        }
    }
}

.ColorModeSelector {
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-family: var(--font);
    color: var(--fg);
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.25rem;
    padding: 0;
    font-size: 0;

    position: absolute;
    left: 0;
    bottom: 0;

    background-image: url('data:image/svg+xml,<svg stroke="currentColor" fill="%23000" stroke-width="0" viewBox="0 0 512 512" height="1.5rem" width="1.5rem" xmlns="http://www.w3.org/2000/svg"><path d="M494.2 221.9l-59.8-40.5 13.7-71c2.6-13.2-1.6-26.8-11.1-36.4-9.6-9.5-23.2-13.7-36.2-11.1l-70.9 13.7-40.4-59.9c-15.1-22.3-51.9-22.3-67 0l-40.4 59.9-70.8-13.7C98 60.4 84.5 64.5 75 74.1c-9.5 9.6-13.7 23.1-11.1 36.3l13.7 71-59.8 40.5C6.6 229.5 0 242 0 255.5s6.7 26 17.8 33.5l59.8 40.5-13.7 71c-2.6 13.2 1.6 26.8 11.1 36.3 9.5 9.5 22.9 13.7 36.3 11.1l70.8-13.7 40.4 59.9C230 505.3 242.6 512 256 512s26-6.7 33.5-17.8l40.4-59.9 70.9 13.7c13.4 2.7 26.8-1.6 36.3-11.1 9.5-9.5 13.6-23.1 11.1-36.3l-13.7-71 59.8-40.5c11.1-7.5 17.8-20.1 17.8-33.5-.1-13.6-6.7-26.1-17.9-33.7zm-112.9 85.6l17.6 91.2-91-17.6L256 458l-51.9-77-90.9 17.6 17.6-91.2-76.8-52 76.8-52-17.6-91.2 91 17.6L256 53l51.9 76.9 91-17.6-17.6 91.1 76.8 52-76.8 52.1zM256 152c-57.3 0-104 46.7-104 104s46.7 104 104 104 104-46.7 104-104-46.7-104-104-104zm0 160c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z"></path></svg>');

    body.dark & {
        background-image: url('data:image/svg+xml,<svg stroke="currentColor" fill="%23fff" stroke-width="0" viewBox="0 0 512 512" height="1.5rem" width="1.5rem" xmlns="http://www.w3.org/2000/svg"><path d="M279.135 512c78.756 0 150.982-35.804 198.844-94.775 28.27-34.831-2.558-85.722-46.249-77.401-82.348 15.683-158.272-47.268-158.272-130.792 0-48.424 26.06-92.292 67.434-115.836 38.745-22.05 28.999-80.788-15.022-88.919A257.936 257.936 0 0 0 279.135 0c-141.36 0-256 114.575-256 256 0 141.36 114.576 256 256 256zm0-464c12.985 0 25.689 1.201 38.016 3.478-54.76 31.163-91.693 90.042-91.693 157.554 0 113.848 103.641 199.2 215.252 177.944C402.574 433.964 344.366 464 279.135 464c-114.875 0-208-93.125-208-208s93.125-208 208-208z"></path></svg>');
    }

    option {
        font-size: 1rem;
    }
}
